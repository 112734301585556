import Swiper from "swiper";
import Viewer from 'viewerjs';

function queueVideoSlideshow() {
    setTimeout(() => {
        $('.video-text-overlay:nth-of-type(1)').fadeOut(2000, () => {
            $('.video-text-overlay:nth-of-type(2)').fadeIn(2000).css('display', 'table-cell');
        });
    }, 6000);

    setTimeout(() => {
        $('.video-text-overlay:nth-of-type(2)').fadeOut(2000, () => {
            $('.video-text-overlay:nth-of-type(3)').fadeIn(2000).css('display', 'table-cell');
        });
    }, 14000);

    /*setTimeout(() => {
        $('.video-text-overlay:nth-of-type(3)').fadeOut(2000, () => {
            $('.video-text-overlay:nth-of-type(4)').fadeIn(2000).css('display', 'table-cell');
        });
    }, 22000);*/


    setTimeout(() => {
        $('.video-text-overlay:nth-of-type(3)').fadeOut(2000, () => {
            $('.video-text-overlay:nth-of-type(1)').fadeIn(2000, () => {
                queueVideoSlideshow();
            }).css('display', 'table-cell');
        });
    }, /*30000*/ 22000);
}

$(function () {
    new Swiper('.homepage-feature-slide:not(.disable-slider)', {
        direction: 'horizontal',
        loop: true,
        pagination: {
            el: '.swiper-pagination',
            clickable: true
        },

        // Navigation arrows
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },

        // And if we need scrollbar
        scrollbar: false,
        centeredSlides: true
    });

    new Swiper('.swipe', {
        direction: 'horizontal',
        loop: true,
        pagination: {
            el: '.swiper-pagination',
            clickable: true
        },

        // Navigation arrows
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },

        // And if we need scrollbar
        scrollbar: false,
        centeredSlides: true
    });

    new Swiper('.swipe-vert', {
        direction: 'vertical',
        loop: true,
        pagination: {
            el: '.swiper-pagination',
            clickable: true
        },

        // Navigation arrows
        navigation: {
            // nextEl: '.swiper-button-next',
            // prevEl: '.swiper-button-prev',
        },

        // And if we need scrollbar
        scrollbar: false,
        centeredSlides: true
    });

    setTimeout(() => {
        $('.video-text-overlay:nth-of-type(1)').fadeIn(2000).css('display', 'table-cell');
    }, 1500);
    queueVideoSlideshow();

    if (document.querySelector('.docs-pictures')) {
        new Viewer(document.querySelector('.docs-pictures'), {
            url: 'data-original',
            backdrop: true,
            button: true,
            fullscreen: true,
            loading: true,
            loop: true,
            keyboard: true,
            movable: false,
            navbar: true,
            rotatable: false,
            scalable: true,
            title: false,
            toggleOnDblclick: true,
            toolbar: false,
            tooltip: false,
            transition: false,
            zoomable: true
        })
    }
});
