$(function () {
    $('.custom-product-form').on('keyup keypress', function (e) {
        let keyCode = e.key;
        if (keyCode === 'Enter') {
            e.stopPropagation();
            e.preventDefault();
            return false;
        }
    });

    $('#submitCustomForm').click(function () {
        $('.custom-product-form').find('[name]').each(function () {
            $(this).removeClass('is-invalid');
        });

        let contactDetails = $('.custom-product-form .contact-details');
        let detailsDetails = $('.custom-product-form .details-container');

        let details = {
            info: {},
            customer: {}
        };
        let errors = {};
        processForm(detailsDetails, details, errors);
        if (contactDetails.attr('style') === '') {
            processForm(contactDetails, details, errors);
        }

        if (Object.keys(errors).length > 0) {
            Object.keys(errors).forEach(function (error) {
                console.log('Adding class to ' + error);
                $(`[name="${error}"]`).addClass('is-invalid');
            })
        } else {
            if (contactDetails.attr('style') !== '') {
                detailsDetails.attr('style', 'display: none;');
                contactDetails.attr('style', '');
                $([document.documentElement, document.body]).animate({
                    scrollTop: 0
                }, 1000);
            } else {
                $(this).attr('disabled', true);
                let formData = new FormData();
                formData.append('utf8', '✓');
                for (const key in details.info) {
                    formData.append("details_" + key, details.info[key]);
                }

                for (const key in details.customer) {
                    if (key === 'address') {
                        formData.append("email_" + key, details.customer[key]);
                    } else {
                        formData.append("contact_" + key, details.customer[key]);
                    }
                }

                formData.append('url', document.location.href);
                let formkeepID = $("input[name='formkeep']").val();
                $.ajax({
                    url: `https://formkeep.com/f/${formkeepID}`,
                    data: formData,
                    processData: false,
                    contentType: false,
                    type: 'POST',
                    success: function (data) {
                        window.location = '/thank-you.aspx';
                    }
                });
            }
        }
    });

    function processForm(form, details, errors) {
        let attr = $(form).attr('class').includes('contact') ? 'customer' : 'info';
        $(form).find('select, input:not([type=file]), textarea').each(function () {
            if ($(this).is(':checkbox')) {
                details[`${attr}`][$(this).attr('name')] = $(this).is(":checked") ? 'yes' : 'no';
            } else if ($(this).hasClass('required')) {
                if ($(this).val() !== "" && $(this).val() !== null) {
                    details[`${attr}`][$(this).attr('name')] = $(this).val();
                } else {
                    errors[$(this).attr('name')] = true
                }
            } else {
                details[`${attr}`][$(this).attr('name')] = $(this).val();
            }
        });
    }
});