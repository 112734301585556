$(function () {
    $('.noStockErrorWrapper').insertBefore('.cartCheckBoxesWrapper');
    if($('.pricesPanel').length !== 0) {
        $('.freeItemsCart').insertAfter('.pricesPanel');
        $('.cartButtonsPanel').insertAfter('.pricesPanel');
    }else{
        $('.freeItemsCart').insertBefore('.cartExtraWrapper');
        $('.cartButtonsPanel').insertBefore('.cartExtraWrapper');
    }


    if ($('#minicartPanel').find('table').length === 0) {
        $('.mini-cart').remove();
    }

    // VPRESS Cart
    if ($('.Cart').length > 0) {
        if ($('.lbCartCode').text().indexOf("VP_") > 0) {
            $('.cssgrid tr ').each(function () {
                if ($(this).children('td:nth-child(2)').children('div').children('.lbCartCode').text().indexOf("VP_") > 0) {
                    // PAck Size
                    let pack_size = $(this).children('td:nth-child(3)').children('div').children('.lblCartProdPack').text();
                    // VP_ amount per pack
                    let vp = $(this).children('td:nth-child(2)').children('div').children('.lbCartCode').text();
                    vp = vp.split('_');
                    // Disable the Qty box
                    $(this).children('td:nth-child(5)').children('div').children('input').css('display', 'none');
                    // Quantity
                    let quantity = $(this).children('td:nth-child(5)').children('div').children('input').val();
                    // total
                    let total = $(this).children('td:nth-child(6)').children('div').children('span').text();

                    // Duplicate Total & put in Price
                    $(this).children('td:nth-child(4)').children('div').children('span').text(total);

                    // Change Qty to text
                    $(this).children('td:nth-child(5)').children('div').append(quantity * vp[1] + '');

                    //Hide pack size
                    $(this).children('td:nth-child(3)').children('div').children('.lblCartProdPack').hide();
                }
            });
        }
    }

    if ($('.cssMassAuthButton').length > 0) {
        let btn = document.createElement('button');
        btn.classList.add('btn');
        btn.classList.add('btn-link');
        btn.classList.add('btn-bm');
        btn.id = 'selectAllAuth';
        btn.innerText = 'Select all';
        btn.onclick = function (e) {
            $('.authListPanel').find('input:checkbox').prop('checked', 'checked');
            e.stopPropagation();
            e.preventDefault();
        }

        $(btn).insertAfter($('.cssMassAuthButton'));
    }
});
