document.addEventListener("DOMContentLoaded", function () {
    let hasModels = $('.modelPanel table tr').length > 1;

    if (hasModels) {
        let modelPanel, modelTable;
        modelPanel = $('.modelPanel');
        modelTable = modelPanel.find('table');

        let links = [];
        modelTable.find('a').each(function (index) {
            links.push($(this).clone());
        });

        modelPanel[0].innerHTML = `
<div id='models'>
    <div class="form-group">
        <label for="searchModels" class="font-size-1">Search: </label>
        <input type="text" class="form-control" id="searchModels" placeholder="Enter your model number..."/>
    </div>
    <div class="searches processed"></div>
</div>`;

        let models = $('#models .searches');
        if (models) {
            for (let i = 0; i < links.length; i++) {
                links[i].addClass('btn btn-link w-20');
                models.append(links[i])
            }
            $('.searches').addClass('processed');
        }

        $('#searchModels').keyup(function (event) {
            let searchTerm = $(this).val();
            if (searchTerm !== '') {
                $('.searches a').each(function (index) {
                    if (!$(this).text().toLowerCase().includes(searchTerm.toLowerCase())) {
                        $(this).attr('style', 'display: none;');
                    } else {
                        $(this).attr('style', '');
                    }
                });
            } else {
                $('.searches a').attr('style', '');
            }
        });
    }
});