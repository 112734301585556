$(document).ready(function ($) {
    let relatedProducts = $('.ProductDetailsRelatedProducts');
    if (relatedProducts.is(':empty')) {
        relatedProducts.parent().remove();
    }

    let additionalProducts = $('.ProductDetailsAdditionalProducts');
    if (additionalProducts.is(':empty')) {
        additionalProducts.parent().remove();
    }

    let specification = $('.ProductDetailsSpecification');
    if (specification.is(':empty')) {
        specification.parent().remove();
    } else if (specification.find('tr').length < 9) {
        $('.ProductDetailsSpecification + button').remove();
    }

    if ($('.Newproductdetailsiconpanel img[alt="no.png"]').length !== 0) {
        $('.no-returns').toggleClass('d-none');
    }

    let features = $('.ProductDetailsFeatures');
    if (features.is(':empty')) {
        features.parent().remove();
    } else if (features.find('tr').length < 9) {
        $('.ProductDetailsFeatures + button').remove();
    }

    let specFeatures = $('.spec_features');
    if ($.trim(specFeatures.html()) === '') {
        specFeatures.remove();
    }

    let textToAdd = $('#attributesTable td.attributeTitle').filter(function () {
        return $(this).text() === "Print";
    }).parent().find('.attributeValue').text();

    let headerPrice = $('.exVat .YourPriceHeaderPrice:first-of-type');
    if (textToAdd !== '') {
        headerPrice.text(headerPrice.text() + ' for ' + textToAdd.toLowerCase());
    }


    let lowerDetails = $('.lowerDetails');
    if ($.trim(lowerDetails.html()) !== '') {
        lowerDetails.toggleClass('processed');
    }

    if ($('.revealAllTableBtn').length !== 0) {
        $('.revealAllTableBtn').click(function () {
            let table = $(this).parent().find('table');
            if (table.hasClass('revealAll')) {
                $(this).text('Show More')
            } else {
                $(this).text('Hide')
            }
            table.toggleClass('revealAll');
        });
    }

    let productImageThumb = $('.prodDetailsImageCont .thumbnail:not(:first-of-type)');
    productImageThumb.click(function (e) {
        e.stopPropagation();
        e.preventDefault();

        let imgLocation = $('.NewproductImage');
        let imgUrl = $(this).attr('href');
        imgLocation.attr('src', imgUrl);
    });
    productImageThumb.mouseenter(function () {
        let imgLocation = $('.NewproductImage');
        window.productImageOld = imgLocation.attr('src');
        let imgUrl = $(this).attr('href');
        imgLocation.attr('src', imgUrl);
    });
    productImageThumb.mouseleave(function () {
        let imgLocation = $('.NewproductImage');
        imgLocation.attr('src', window.productImageOld);
        delete window.productImageOld;
    });


    let qtyBreaksUL = $('.QtyBreaksList');
    if (qtyBreaksUL.find('li').length !== 0) {
        qtyBreaksUL.find('li:first-of-type').before('<li class="QtyBreakListItem"><span class="QBQtySpan">Qty</span>at<span id="QBQtySpan1.7300" class="QBQtySpan">Price</span>each</li>');
    }

    let stockLabel = $('.web-price .stockLabel');
    if (document.body.classList.contains('sector_50') ||
        document.body.classList.contains('sector_51') ||
        document.body.classList.contains('sector_52') ||
        document.body.classList.contains('sector_53') ||
        document.body.classList.contains('sector_54')) {

        if (stockLabel.text() === "Item is not in stock") {
            stockLabel.html("Please contact us for availability")
        }else{
            stockLabel.html(stockLabel.text().toLowerCase().replace("in stock", "Stock Available"))
        }
    }

    if (stockLabel.text() === "Item is not in stock") {
        stockLabel.html("Please contact us for availability")
    }


    if(document.querySelector('.prodDetailAuthDropDown')) {
        $('.prodDetailAuthDropDown').insertBefore('.buyDiv');
    }
});
