import 'whatwg-fetch';
import '@babel/polyfill';

function isMobile() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

document.addEventListener("DOMContentLoaded", function () {
    if (document.getElementsByTagName('iframe').length !== 0) {
        if (window.MSInputMethodContext && document.documentMode) {
            document.getElementsByTagName("iframe")[0].height = '2500px';
            document.getElementsByTagName("iframe")[0].style.cssText = "width:100%;overflow: scroll;border: none;";
        } else {
            document.body.classList.add('notie11');
            document.getElementsByTagName("iframe")[0].style.cssText = "width:100%;overflow: scroll;border: none;";
            document.getElementsByTagName("iframe")[0].scrolling = 'yes';
        }
    }

    if (isMobile()) {
        document.body.className += ' mobileDevice';
    }

    /**
     * Make it so the ECi version string etc is still visible in custom footer.
     * @type {string}
     */
    document.getElementById('horizonVersion').innerText = document.getElementById('versionLabel').innerText;
    if (document.getElementById('adminHyperLink') !== null) {
        document.getElementById('adminBtn').style.display = "block";
    }

    /**
     * JS for mobile only menu system adds clicks for showing submenu part
     */
    $('.mobile .hide').click(function () {
        $(this).parent().find('.submenu').hide();
        $(this).find('.submenu').show();
    });

    /**
     * When click full width video image - hide and play actual video
     */
    $(document).on('click', '.fullWidthVideo', function () {
            $(this).find('img').remove();
            $(this).find('video')[0].play();
    });
    $(document).on('touchstart', '.fullWidthVideo', function () {
            $(this).find('img').remove();
            $(this).find('video')[0].play();
    });

    /**
     * Count entries in mini cart and append badge
     * @type {jQuery|HTMLElement}
     */
    let table = $('.mini-cart .minicartcontrol table');
    if (table.length) {
        let count = table.find('tr').length / 2;

        $('.headerCartWrapper').addClass('hasItems');
        $('.headCart').append(`<span class='badge'>${count}</span>`);
    }

    /**
     * Product page processing
     * Its in this JS file so it gets executed ASAP
     */
    const stockLabels = document.querySelectorAll('.productsStockLabel');
    for (let i = 0; i < stockLabels.length; i++) {
        if(!stockLabels[i].innerText.includes('not in')){
            stockLabels[i].classList.add('processed');
        }
    }

    setTimeout(function () {
        $('.productsStockLabel').each(function () {
            if (!$(this).text().includes('not in')) {
                $(this).addClass('processed');
            }
        });
    }, 3500);

    /**
     * FIX ECi random ass formatting in output of content.
     * @type {jQuery|HTMLElement}
     */
    let savedorderscontrol = $('.savedorderscontrol');
    if (savedorderscontrol.length !== 0) {
        if (savedorderscontrol[0].innerHTML.trim().length === 0) {
            savedorderscontrol.remove();
        }
    }

    /**
     * Make iframe feature of horizon actually usable by making it a reasonable width and height and actually scrollable.
     */
    $('iframe').attr('scrolling', 'yes').attr('style', 'width: 100%; overflow: scroll');

    /**
     * OrderPad Fix
     */
    if($('.SectorBulletedList')){
        $('.SectorBulletedList').find('a:contains("web-opad-fix")').parent().remove();
    }

    if($('img[title="Contract Item"]').length !== 0 && document.body.className.includes('ProductDetails')){
        console.log('contract')
        document.body.className = 'ProductDetails';
    }

    if ($('input[id*="callOffCheckBox"]').length !== 0 && document.body.className.includes('ProductDetails')) {
        console.log('call off')
        document.body.className = 'ProductDetails';
    }
});
import './polyfills/find';
import './self-serve';
import './cart';
import './drift';
import './search';
import './productdetails';
import '../sass/screen.scss';
import './customProductForms';
import "./widgets";
import "./consumables";
import 'bootstrap/js/dist/collapse';
