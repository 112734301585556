function isMobile() {
    return $(window).width() <= 1200;

    // return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

window.ToggleFilter2 = ToggleFilter.bind({});
window.ToggleFilter = function (str) {
    let s = str.replace('Pop', '').replace(/([A-Z])/g, ' $1').trim().replace(' ', '_');
    $(`.${s}`).remove();
    ToggleFilter2(str);
};

$(function ($) {


    $(window).resize(function () {
        if (!isMobile()) {
            $('.mobileHeader').hide();
            $('.desktopHeader').show();
        } else {
            $('.mobileHeader').show();
            $('.desktopHeader').hide();
        }
    })

    $('.toggleMenu').on('click', function () {
        $('.menutable').toggleClass('in');
    });

    let header = $('.headerKeywords');
    header.keyup(function (event) {
        let searchTerm = $(this).find('.keywordSearch').val().replace('  ', ' ').replace('  ', ' ');
        let actualSearchTerm;

        //keywords
        switch (searchTerm.toLowerCase()) {
            case "paper":
                actualSearchTerm = "kwpaper";
                break;
            case "desks":
                actualSearchTerm = "KWDesks";
                break;
            case "a4 paper":
            case "a4paper":
                actualSearchTerm = "kwa4paper";
                break;
            case "a 3 paper":
            case "a3paper":
                actualSearchTerm = "a3 paper";
                break;
            case "coffee":
                actualSearchTerm = "kwcoffee";
                break;
            case "office chair":
                actualSearchTerm = "KWoperatorseating";
                break;
            case "post-it":
                actualSearchTerm = "promo-postit";
                break;
            case "post-it notes":
                actualSearchTerm = "promo-postitnotes";
                break;
            case "large post-it notes":
                actualSearchTerm = "promo-largepostitnotes";
                break;
            case "yellow post-it notes":
                actualSearchTerm = "promo-yellowpostitnotes";
                break;
            default:
                actualSearchTerm = searchTerm;
                break;
        }

        $('.realHeaderKeywords input[id*="keywordTextBox"]').val(actualSearchTerm);
    });

    header.keydown(function (event) {
        if (event.key === "Enter") {
            if ($(this).find('.keywordSearch').val() !== "") {
                let self = this;
                setTimeout(function () {
                    $(self).find('.searchBtn').click();
                }, 100);
            }
            event.preventDefault();
        }
    });

    $('.searchBtn').click(function () {
        let keyword = $(this).parents('.headerKeywords').find('.keywordSearch').val().toLowerCase();
        switch (keyword) {
            case "heatons":
                window.location = "/contact-us.aspx";
                break;
            case "facemask":
            case "facemasks":
            case "face mask":
            case "face masks":
            case "masks":
            case "mask":
                window.location = "/Respiratory-Protection.aspx";
                break;
            default:
                $('input[id*="_keywordgoImageButton"]').click();
                break;
        }
    });

    $('.mobileHeader .toggleSearch').click(function () {
        $('.theSearchRow').toggleClass('d-block');
    })
});




