function isMobile() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

if (!isMobile()) {
    /*!function () {
        let t = window.driftt = window.drift = window.driftt || [];
        if (!t.init) {
            if (t.invoked) return void (window.console && console.error && console.error("Drift snippet included twice."));
            t.invoked = !0, t.methods = ["identify", "config", "track", "reset", "debug", "show", "ping", "page", "hide", "off", "on"],
                t.factory = function (e) {
                    return function () {
                        let n = Array.prototype.slice.call(arguments);
                        return n.unshift(e), t.push(n), t;
                    };
                }, t.methods.forEach(function (e) {
                t[e] = t.factory(e);
            }), t.load = function (t) {
                let e = 3e5, n = Math.ceil(new Date() / e) * e, o = document.createElement("script");
                o.type = "text/javascript", o.defer = !0, o.crossorigin = "anonymous", o.src = "https://js.driftt.com/include/" + n + "/" + t + ".js";
                let i = document.getElementsByTagName("script")[0];
                i.parentNode.insertBefore(o, i);
            };
        }
    }();
    drift.on('ready', function (api, payload) {
        if (Object.keys(user_details).length !== 0 && user_details.constructor === Object) {
            console.log('Setting drift user details...');
            let id = user_details.user + "-" + user_details.trader_code;
            drift.identify(id, {
                name: user_details.user,
                firstName: user_details.user.split(' ')[0],
                lastName: user_details.user.split(' ')[1],
                displayName: user_details.user,
                company: user_details.trader_name,
                Tradercode: user_details.trader_code,
                email: user_details.email,
                phone_number: user_details.phone_number.match(/[^$,.\d]/) ? '0000 000 0000' : user_details.phone_number,
                employmentRole: 'Customer',
                employmentTitle: 'Customer',
                employmentName: user_details.trader_name
            })
        }
    });
    drift.SNIPPET_VERSION = '0.3.1';
    drift.load('ps2zx68k4kt5');*/
}
