function handleKeyDown () {
    $(this).removeClass('is-invalid');
}

function isNumber(val) {
    return /^[-]?\d+$/.test(val);
}

$(document).ready(function ($) {
    $('#selfServeEmail').keydown(handleKeyDown);

    $('#submitSelfPasswordReset').click(() => {
        let emailBox = $('#selfServeEmail');
        let errors = false;

        if(emailBox.val().length < 5 || !emailBox.val().includes('@')){
            emailBox.addClass('is-invalid');
            errors = true;
        }

        if(errors)
            return;

        $('#submitSelfPasswordReset').prop("disabled", true);
        let spin = $(this).find('.spinner');
        spin.show();

        $.ajax({
            url: `https://api.heatons.net/api/user/setup`,
            data: JSON.stringify({
                Email: emailBox.val()
            }),
            dataType: "json",
            contentType: "application/json",
            type: "POST",
            success: function (data) {
                $('#submitSelfPasswordReset').prop("disabled", false);
                spin.hide();
                if(!data.error){
                    $('#selfServeAlert')[0].innerHTML = 'Password has been successfully reset. You should receive an email in next few minutes with your new password.';
                    $('#selfServeAlert').removeClass('d-none');
                }else{
                    $('#selfServeAlert').removeClass('d-none');
                    console.log("ERROR: " + data.message);
                }
            }
        });
    });
});
